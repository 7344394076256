import React, { useEffect, useState } from 'react';
import Header from '../header';
import Footer from '../footer';
import axios from 'axios';

const Layout = ({ children }) => {

    const [pageData, setPageData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    'https://hallandsons.sociallexicon.com/backend/wp-json/v1/social-links'
                );

                setPageData(response.data || {});
            } catch (error) {
                console.log("🚀 ~ fetchData ~ error:", error)
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Header data={pageData} />
            <main>{children}</main>
            <Footer data={pageData} />

        </>
    );
};

export default Layout;