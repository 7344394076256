import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Watertreatment from '../components/waterTreatment';
import WhiteScreenOverlay from '../components/overlay/overLay';

const WaterTreatment = () => {

    const [pageData, setPageData] = useState();
    const [isOverlayVisible, setIsOverlayVisible] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    'https://hallandsons.sociallexicon.com/backend/wp-json/v1/services/181'
                );

                setPageData(response.data?.acf_fields || {});
            } catch (error) {
                console.log("🚀 ~ fetchData ~ error:", error)
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // Simulate loading of data or a task
        const timer = setTimeout(() => {
            setIsOverlayVisible(false); // Hide overlay and show content after 1 second
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        !isOverlayVisible ? <Watertreatment data={pageData} /> : <WhiteScreenOverlay/>
    );

}

export default WaterTreatment;