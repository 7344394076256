/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import parse from 'html-react-parser';

const ArticleDetail = (props) => {
  const { data } = props;

  return (
    <>
      <section
        className="contactMainBg"
        data-aos="fade-in"
        data-aos-duration="1000"
        style={{ backgroundImage: `url(${data?.featured_image})` }}
      >
        <div className="container custom-container">
          <div
            className="articleHeading serviceHeading text-center"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            {/* <h2 className="mobService" data-heading={data?.contact_form_data?.post_title}>
              <span>{data?.contact_form_data?.post_title}</span>
            </h2> */}
          </div>
        </div>
      </section>
      <div className="container custom-container mt-5">
        <div
          className="aboutPageDtls mb-3 aos-init aos-animate"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <h2>{data?.contact_form_data?.post_title}</h2>
          {data?.contact_form_data?.post_date &&
            new Date(data.contact_form_data.post_date).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
        </div>
        <div
          className="aboutPageDtls aos-init aos-animate"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          {parse(data?.contact_form_data?.post_content ?? "")}
          {/* <img src={data?.featured_image} className="img-fluid w-full my-6" /> */}
        </div>
        
       
      </div>
    </>
  );
};

export default ArticleDetail;
