import React, { useState } from "react";
import parse from "html-react-parser";

const FAQ = (props) => {
  const { data } = props;
  const [activeIndex, setActiveIndex] = useState(0); // By default, index 0 is active

  return (
    <>
      <section
        className="faqBg"
        style={{
          backgroundImage: `url(${data?.faq_section?.faq_banner_image})`,
        }}
        data-aos="fade-in"
        data-aos-duration="1000"
      >
        <div className="container custom-container">
          <div
            className="faqHeading"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            <div
              className="serviceHeading mb-0"
              data-aos="fade-in"
              data-aos-duration="1000"
            >
              <h2 className="mobService">
                <span>{data?.faq_section?.faq_heading}</span>
              </h2>
            </div>
            {parse(data?.faq_section?.faq_description ?? "")}
          </div>
        </div>
      </section>

      <section
        className="faqListBg"
        style={{
          backgroundImage: `url(${data?.faq_section?.faq_background_image})`,
        }}
      >
        <div className="container custom-container">
          <div className="faqListing">

            <div className="accordion custom-accordion" id="accordionExample">
              {data?.faq_section?.faq_accordion.map((accord, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    {" "}
                    <button
                      className={`accordion-button ${activeIndex === index ? "" : "collapsed"
                        }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded={activeIndex === index ? "true" : "false"}
                      aria-controls={`collapse${index}`}
                      onClick={() =>
                        setActiveIndex(activeIndex === index ? -1 : index)
                      }
                    >
                      {" "}
                      {parse(accord?.faq_question)}{" "}
                    </button>{" "}
                  </h2>{" "}
                  <div
                    id={`collapse${index}`}
                    className={`accordion-collapse collapse ${index === 0 ? "show" : ""
                      }`}
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    {" "}
                    <div className="accordion-body">
                      {" "}
                      {parse(accord?.faq_answer)}{" "}
                    </div>{" "}
                  </div>{" "}
                </div>
              ))}{" "}
            </div>

            <div
              className="getMsg text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2>
                {data?.faq_section?.question_blue_heading}&nbsp;
                <span>{data?.faq_section?.question_red_heading}</span>
              </h2>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQ;
