import React from 'react';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';

const Articles = (props) => {
    const navigate = useNavigate();

    const { data } = props;

    return (
        <>
            <section className="articlesBg" style={{ backgroundImage: `url(${data?.acf_fields?.articles_section?.article_image})` }} data-aos="fade-in" data-aos-duration="1000">
                <div className="container custom-container">
                    <div className="articleMainText text-center" data-aos="fade-in" data-aos-duration="1000">
                        <div className="serviceHeading text-center" data-aos="fade-in" data-aos-duration="1000">
                            <h2 className="mobService" >
                                <span>
                                    {data?.acf_fields?.articles_section?.article_heading}
                                </span>
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className="articlesDtls">
                <div className="container custom-container">
                    <div className="recentArticleTxt" data-aos="fade-in" data-aos-duration="1000">
                        <h2 className="mobService" >
                            <span>
                                {data?.acf_fields?.articles_section?.recent_article_heading}
                            </span>
                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row">
                                {data?.post_data?.map((post) => (
                                    <div className="col-lg-6 col-md-6" key={post?.id}>
                                        <div className="card cardBorder" data-aos="fade-up" data-aos-duration="800">
                                            <div className="articleImgSz">
                                                <a href="#!" aria-label={`Read more about ${post?.title}`}>
                                                    <img
                                                        className="card-img-top"
                                                        src={post?.featured_image}
                                                        alt={post?.title}
                                                    />
                                                </a>
                                            </div>
                                            <div className="card-body">
                                                <h2 className="card-title cardMainTitle">{post?.title}</h2>
                                                {parse(post?.description)}
                                                <div className="dateReadBtn">
                                                    <button
                                                        className="viewArticle"
                                                        aria-label={`View article: ${post?.title || "Untitled Article"}`}
                                                        onClick={() => { window.scrollTo(0, 0); navigate(`/article-details?id=${post?.id}`) }} // Add id as query parameter
                                                    >
                                                        VIEW ARTICLE
                                                        <i className="fa-solid fa-arrow-right-long ps-1"></i>
                                                    </button>
                                                    <div className="small spaceCategoryTop">
                                                        <i className="fa-solid fa-calendar-days pe-1"></i>
                                                        {post?.post_date &&
                                                            `${new Date(post.post_date).toLocaleString('en-US', { month: 'short' })} ${new Date(post.post_date).getDate()}`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="widgetsWitdh">
                                <div className="mostPopArticleTxt" data-aos="fade-in" data-aos-duration="1000">
                                    <h2>MOST POPULAR</h2>
                                </div>
                                <div className="categoryWidth mb-5" data-aos="fade-up" data-aos-duration="1000">
                                    <div className="list-group list-group-flush">
                                        {data?.popular_post?.map((post) => (

                                            <div className="listingCategory" key={post?.ID || `post-${Math.random()}`}>
                                                < button aria-label={`View article: ${post?.post_title || "Untitled Article"}`}
                                                    onClick={() => {
                                                        window.scrollTo(0, 0);
                                                        navigate(`/article-details?id=${post?.ID}`);
                                                    }}
                                                    className="viewArticle">{post?.post_title || "Untitled Post"}</button>
                                                <div className="small spaceCategoryTop mt-2">
                                                    <i className="fa-solid fa-calendar-days pe-1"></i>
                                                    {post?.post_date &&
                                                        `${new Date(post.post_date).toLocaleString('en-US', { month: 'short' })} ${new Date(post.post_date).getDate()}`}
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Articles;