import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Aboutus from './pages/aboutUS';
import Contactus from './pages/contactUS';
import FAQComponent from './pages/faq';
import NewConstructions from './pages/newConstruction';
import RemodelRenovation from './pages/remodelRenovation';
import GenralServiceAndRepair from './pages/generalServiceandRepair';
import WaterTreatment from './pages/waterTreatment';
import WaterHeater from './pages/waterHeater';
import Articles from './pages/articles';
import ArticleDetails from './pages/articleDetail';
import Layout from './components/layout';

function App() {

  useEffect(() => {
    AOS.init();
  }, [])
  

  return (
    <div className="App">
      <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<Aboutus />} />
            <Route path="/contact-us" element={<Contactus />} />
            <Route path="/faq" element={<FAQComponent />} />
            <Route path="/newConstruction" element={<NewConstructions />} />
            <Route path="/remodel-renovation" element={<RemodelRenovation />} />
            <Route path="/general-service-repair" element={<GenralServiceAndRepair />} />
            <Route path="/water-treatment" element={<WaterTreatment />} />
            <Route path="/water-heater" element={<WaterHeater />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/article-details" element={<ArticleDetails />} />

          </Routes>
      </Layout>
    </div>
  );
}

export default App;
