/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react'
import pipe from "./../../assets/images/pipe.png";
import pipe2 from "./../../assets/images/pipe2.png";
import wrench from "./../../assets/images/wrench.png";
import toolImage from "./../../assets/images/tool.png";

import parse from 'html-react-parser';

const Watertreatment = (props) => {

    const { data } = props;

    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = data?.feature_section?.feature_images || [];
    const [isFullscreen, setIsFullscreen] = useState(false);

    const openImageModal = (image) => {
        setSelectedImage(image);
        setIsImageModalOpen(true);
    };

    // Handle close modal
    const closeImageModal = (e) => {
        e.stopPropagation();  // Prevents the click event from bubbling up
        setIsImageModalOpen(false);  // Close the modal
    };


    const handlePrevImage = () => {
        const prevIndex = currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1;
        setCurrentImageIndex(prevIndex);
        setSelectedImage(images[prevIndex]);
    };

    const handleNextImage = () => {
        const nextIndex = currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1;
        setCurrentImageIndex(nextIndex);
        setSelectedImage(images[nextIndex]);
    };

    const handleFullscreen = () => {
        if (isFullscreen) {
            // Check if the modal is in fullscreen and exit fullscreen
            if (document.fullscreenElement === document.getElementById('bookingModal')) {
                document.exitFullscreen();
            }
        } else {
            // Request fullscreen for the modal itself
            const modalElement = document.getElementById('bookingModal');
            if (modalElement?.requestFullscreen) {
                modalElement.requestFullscreen();
            }
        }
        setIsFullscreen(!isFullscreen);
    };



    return (
        <>
            <section className="remodelRenovationBg" style={{ backgroundImage: `url(${data?.service_section?.service_main_image})` }} data-aos="fade-in" data-aos-duration="1000">
                <div className="container custom-container">
                    <div className="constructionLogo" data-aos="fade-down" data-aos-duration="1000">

                        <img src={data?.service_section?.service_heading_image} alt="" />

                    </div>
                    <div className="aboutPageDtls" data-aos="fade-in" data-aos-duration="1000">
                        <h2>{data?.service_section?.service_care_heading}</h2>
                        {typeof data?.service_section?.service_care_description === "string"
                            ? parse(data?.service_section?.service_care_description)
                            : null}
                    </div>
                </div>
            </section>

            <section className="repipingBg">
                <div className="container custom-container">
                    <div className="container">
                        {data?.offer_section?.offer_details?.map((detail, index) => (
                            <div
                                key={index}
                                className={`row align-items-center repipingBoxesDistance ${index % 2 !== 0 ? 'flex-row-reverse' : ''
                                    }`}
                            >
                                {/* Column for Image */}
                                <div className="col-lg-6">
                                    <div
                                        className="constructionImg1 spaceBothImages"
                                        data-aos="fade-up"
                                        data-aos-anchor-placement="top-bottom"
                                        data-aos-duration="1000"
                                    >
                                        <img
                                            className="mb-5"
                                            src={detail?.offer_image}
                                            alt={`Offer ${index + 1}`}
                                        />
                                    </div>
                                </div>

                                {/* Column for Text */}
                                <div className="col-lg-6">
                                    <div
                                        className="constructionDetails"
                                        data-aos="fade-up"
                                        data-aos-anchor-placement="top-bottom"
                                        data-aos-duration="1000"
                                    >
                                        <h2>{detail?.offer_heading}</h2>
                                        {typeof detail?.offer_desciption === "string" &&
                                            detail.offer_desciption.trim()
                                            ? parse(detail.offer_desciption)
                                            : null}

                                    </div>
                                </div>
                            </div>
                        ))}
                        <p style={{ color: "white" }}>
                            We also provide the installation service for a water treatment Loop and Drain, a necessary piece of household hardware for a softener to be installed.

                        </p>
                    </div>

                </div>
                <div className="constructionTools1" data-aos="zoom-in" data-aos-duration="1200">
                    <img src={pipe} alt="" />
                </div>
                <div className="constructionTools2" data-aos="zoom-in" data-aos-duration="1200">
                    <img src={pipe2} alt="" />
                </div>
                <div className="constructionTools3" data-aos="zoom-in" data-aos-duration="1200">
                    <img src={wrench} alt="" />
                </div>
                <div className="constructionTools4" data-aos="zoom-in" data-aos-duration="1400">
                    <img src={toolImage} alt="" />
                </div>
            </section>

            <section className="ourProcessBg" style={{ backgroundImage: `url(${data?.process_section?.process_main_image})` }}>
                <div className="container custom-container">
                    <div className="ourProcessHeading text-center" data-aos="fade-in" data-aos-duration="1000">
                        <div className="serviceHeading text-center" data-aos="fade-in" data-aos-duration="1000">
                            <h2 className="mobService" >
                                <span>
                                    {data?.process_section?.process_heading}
                                </span>
                            </h2>
                        </div>
                    </div>
                    <div className="row clearDistance">
                        {data?.process_section?.process_details.map((process, index) => (
                            <div
                                key={index}
                                className="col-sm-6 col-md-6 col-sm-12"
                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-duration="800"
                            >
                                <div className="renovateBoxText">
                                    <h2>{process?.process_detail_heading}</h2>
                                </div>
                                <div className="features-renovate">
                                    <div className="features-icon">
                                        <img src={process?.process_detail_image} alt="" />
                                    </div>
                                    <div className="features-info">
                                        {typeof process?.process_detail_description === "string"
                                            ? parse(process.process_detail_description)
                                            : null}
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>

            <section className="servicesSection photo-gallery">
                <div className="container custom-container">

                    <div className="featuredWorkPg  serviceHeading text-center" data-aos="fade-in" data-aos-duration="1000">
                        <h2 className="mobService" >
                            <span>
                                {data?.feature_section?.feature_heading}
                            </span>
                        </h2>
                    </div>
                    <div className="galleryImgs">

                    </div>

                    <div className="photo-gallery">

                        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-4 gallery-grid">
                            {data?.feature_section?.feature_images.map((image, index) => (
                                <div
                                    className="col aos-init aos-animate"
                                    data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom"
                                    data-aos-duration={800}
                                    key={index}
                                >
                                    <a className="gallery-item" onClick={(e) => {
                                        e.preventDefault(); // Prevent default behavior
                                        openImageModal(image, `Image ${index + 1}`);
                                        setCurrentImageIndex(index); // Store the index of the selected image

                                    }}>
                                        <img className="" src={image} alt={`Image ${index + 1}`} />
                                    </a>
                                </div>))}
                        </div>
                    </div>

                    {isImageModalOpen && (
                        <div id="bookingModal" className="modal">
                            <div className='modalControls'>
                                <i className="fa-solid fa-expand" onClick={handleFullscreen}></i>
                                <i onClick={closeImageModal} className="fa-solid fa-xmark"></i>
                            </div>
                            <div className='modalImgWrapper'>
                                <button onClick={handlePrevImage} className="modal-nav-button prev" aria-label="Previous Image">
                                    &lt;
                                </button>
                                <div className="modalCaroselImg">
                                    <img className="modal-content galleryModalImg" src={selectedImage} alt="Modal View" style={{ cursor: "pointer" }} />
                                </div>
                                <button onClick={handleNextImage} className="modal-nav-button next" aria-label="Next Image">
                                    &gt;
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <section className="aboutLicense">
                <div className="container custom-container">
                    <div className="abtCenterLicense text-center" data-aos="fade-in" data-aos-duration="1000">
                        <img src={data?.feature_section?.license_image} alt="" className='mx-auto' />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Watertreatment;