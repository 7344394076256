import React from 'react';

const ContactUS = (props) => {

    const { data } = props;
    
    return (
        <section className="contactMainBg" style={{ backgroundImage: `url(${data?.contact_section?.contact_main_image})` }} data-aos="fade-in" data-aos-duration="1000">
            <div className="container custom-container">
                <div className="contactText text-center" data-aos="fade-in" data-aos-duration="1000">

                    <div className="serviceHeading text-center" data-aos="fade-in" data-aos-duration="1000">
                        <h2 className="mobService" >
                            <span>
                                {data?.contact_section?.contact_heading}
                            </span>
                        </h2>
                    </div>
                </div>
            </div>

        </section>

    )
}

export default ContactUS;