/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useCallback, useEffect, useState } from 'react';
import whiteLogo from "./../../assets/images/white-logo.png";
import socialLexian from "./../../assets/images/social-lexicon.png";
import axios from 'axios';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Footer = (props) => {
    const navigate = useNavigate();

    const { data } = props;

    const [pageData, setPageData] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState("");
    const [number, setNumber] = useState("");
    const [selectedService, setSelectedService] = useState("General Service Repair");
    const [formSubmitted, setFormSubmitted] = useState(false); // Track successful submission
    const [formErrorMsg, setFormErrorMsg] = useState("");
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleServiceChange = (event) => {
        setSelectedService(event.target.value); // Update selected service
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    'https://hallandsons.sociallexicon.com/backend/wp-json/v1/contact-form'
                );

                setPageData(response.data?.contact_form_data || {});
            } catch (error) {
                console.log("🚀 ~ fetchData ~ error:", error)
            }
        };

        fetchData();
    }, []);

    const handleRecaptcha = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return false; // Stop form execution
        }

        try {
            const token = null

            if (token) {
                const response = await axios.post(
                    'https://hallandsons.sociallexicon.com/backend/wp-json/v1/google-verification/',
                    { token: "ggdfggf" }
                );

                if (response.data.success) {
                    console.log("Recaptcha verification succeeded");
                    return true; // Allow form to proceed
                } else {
                    console.log("Recaptcha verification failed with errors:", response.data["error-codes"]);
                    response.data["error-codes"].forEach(err => {
                        console.log("Error code:", err);
                        setFormErrorMsg("Recaptcha verification failed.Please try again")
                    });
                    setTimeout(() => {
                        setFormErrorMsg("");
                    }, 10000);
                    setFormSubmitted(false);

                    reset();
                    return false; // Stop form execution
                }
            }
        } catch (e) {
            console.log("Error during recaptcha handling:", e);
            setFormErrorMsg("Recaptcha verification failed.Please try again");
            setTimeout(() => {
                setFormErrorMsg("");
            }, 10000);
            setFormSubmitted(false);
            reset();
            return false; // Stop form execution
        }
    }, [executeRecaptcha]);


    const reset = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setMsg("");
        setNumber("");
        setSelectedService("General Service Repair");
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent form reload

        try {

            if (firstName === "" || lastName === "" || msg === "") {
                setFormErrorMsg("Empty fields are not allowed!");
                setTimeout(() => {
                    setFormErrorMsg("");
                }, 10000);
                setFormSubmitted(false);

                reset();
                return; // Stop further execution
            }

            // Email validation
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailRegex.test(email)) {
                setFormErrorMsg("Please enter a valid email address");
                setTimeout(() => {
                    setFormErrorMsg("");
                }, 10000);
                setFormSubmitted(false);

                reset();
                return; // Stop further execution
            }

            // Phone number validation
            const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

            if (!phoneRegex.test(number)) {
                setFormErrorMsg("Please enter a valid phone number");
                setTimeout(() => {
                    setFormErrorMsg("");
                }, 10000);
                setFormSubmitted(false);

                reset();
                return; // Stop further execution
            }

            const recaptchaVerified = await handleRecaptcha();

            if (!recaptchaVerified) {
                setFormErrorMsg("Recaptcha verification failed.Please try again");
                setTimeout(() => {
                    setFormErrorMsg("");
                }, 10000);
                setFormSubmitted(false);
                reset();
                return; // Stop form execution
            }
            const serviceKeyMapping = {
                "General Service Repair": "input_8_1",
                "Water Treatment": "input_8_3",
                "Remodel & Renovation": "input_8_4",
                "New Construction": "input_8_5",
            };


            const dynamicKey = serviceKeyMapping[selectedService];
            console.log("🚀 ~ handleSubmit ~ dynamicKey:", dynamicKey)

            // Send POST request
            const response = await axios.post(
                'https://hallandsons.sociallexicon.com/backend/wp-json/gf/v2/forms/1/submis ons',
                {
                    "input_4": firstName,
                    "input_5": lastName,
                    "input_6": email,
                    "input_7": number,
                    [dynamicKey]: selectedService,
                    "input_9": msg
                }
            );

            // Handle success
            if (response.status === 200) {
                console.log('API Response:', response.data);
                reset()
                setFormSubmitted(true);
                setFormErrorMsg(parse(response.data.confirmation_message));
                setTimeout(() => {
                    setFormErrorMsg("");
                }, 10000);

            } else {
                console.log('Unexpected response:', response);
                setFormErrorMsg(response.data)
                reset();
                setTimeout(() => {
                    setFormErrorMsg("");
                }, 10000);
                setFormSubmitted(false);

            }
        } catch (error) {
            // Handle error
            console.log('Error sending selected service:', error);
            setFormErrorMsg(error.message || error.request || error.response.data)
            reset();
            setTimeout(() => {
                setFormErrorMsg("");
            }, 10000);
            setFormSubmitted(false);


            if (error.response) {
                console.error('Server Response:', error.response.data);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error details:', error.message);
            }
        }
    };

    return (
        <>
            <section className="contactUsBg">
                <div className="container custom-container" >
                    <div className="contactFormBgWhite" data-aos="fade-in" data-aos-duration="10000">
                        <div className="row">
                            <div className="col-md-6 col-lg-5">
                                <div className="bgOrangeAddress">
                                    <img src={pageData?.contact_form_image} alt="" />
                                    <div className="iconsWithPara">
                                        <div className="contactDetails">
                                            <h4>{pageData?.contact_form_heading}</h4>
                                            {typeof pageData?.contact_form_description === "string"
                                                ? parse(pageData?.contact_form_description)
                                                : null}
                                        </div>
                                        <div className="socialIconsWithPlumberImg">
                                            <div className="socialIcons">
                                                <a href={data?.acf_fields[1]?.url}><i className={data?.acf_fields[1]?.icon}></i></a>
                                                <a href={data?.acf_fields[2]?.url}><i className={data?.acf_fields[2]?.icon}></i></a>
                                                <a href={data?.acf_fields[0]?.url}><i className={data?.acf_fields[0]?.icon}></i></a>
                                            </div>
                                            <div className="bgPlumberImg">
                                                <img src={pageData?.contact_form_footer_image} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7">

                                <div className="formInsideSpace">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="contactHome">
                                                    <p>First Name</p>
                                                    <input
                                                        id="firstName"
                                                        name="firstName"
                                                        type="text"
                                                        value={firstName}
                                                        onChange={(e) => setFirstName(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="contactHome">
                                                    <p>Last Name</p>
                                                    <input
                                                        id="lastName"
                                                        name="lastName"
                                                        type="text"
                                                        value={lastName}
                                                        onChange={(e) => setLastName(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="contactHome">
                                                    <p>Email</p>
                                                    <input
                                                        id="email"
                                                        name="email"
                                                        type="text"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="contactHome">
                                                    <p>Phone Number</p>
                                                    <input
                                                        id="number"
                                                        name="number"
                                                        type="text"
                                                        value={number}
                                                        onChange={(e) => setNumber(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="selectServices">
                                                    <h2>Select Services</h2>

                                                    <ul className="radioDirection">
                                                        <li>
                                                            <label className="radioContainer">
                                                                <input
                                                                    type="radio"
                                                                    name="services"
                                                                    value="General Service Repair"
                                                                    checked={selectedService === "General Service Repair"}
                                                                    onChange={handleServiceChange}
                                                                />
                                                                <span className="checkmark"></span>General Service Repair
                                                            </label>
                                                        </li>

                                                        {/* <li>
                                                            <label className="radioContainer">
                                                                <input
                                                                    type="radio"
                                                                    name="services"
                                                                    value="Water Heater"
                                                                    checked={selectedService === "Water Heater"}
                                                                    onChange={handleServiceChange}
                                                                />
                                                                <span className="checkmark"></span>Water Heater
                                                            </label>
                                                        </li> */}

                                                        <li>
                                                            <label className="radioContainer">
                                                                <input
                                                                    type="radio"
                                                                    name="services"
                                                                    value="Water Treatment"
                                                                    checked={selectedService === "Water Treatment"}
                                                                    onChange={handleServiceChange}
                                                                />
                                                                <span className="checkmark"></span>Water Treatment
                                                            </label>
                                                        </li>

                                                        <li>
                                                            <label className="radioContainer">
                                                                <input
                                                                    type="radio"
                                                                    name="services"
                                                                    value="Remodel & Renovation"
                                                                    checked={selectedService === "Remodel & Renovation"}
                                                                    onChange={handleServiceChange}
                                                                />
                                                                <span className="checkmark"></span>Remodel & Renovation
                                                            </label>
                                                        </li>

                                                        <li>
                                                            <label className="radioContainer">
                                                                <input
                                                                    type="radio"
                                                                    name="services"
                                                                    value="New Construction"
                                                                    checked={selectedService === "New Construction"}
                                                                    onChange={handleServiceChange}
                                                                />
                                                                <span className="checkmark"></span>New Construction
                                                            </label>
                                                        </li>


                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="contactHome border-bottom-0">
                                                    <p className='mb-0'>Message</p>
                                                    <textarea className='textareaField' rows="4" placeholder="Write your message.."
                                                        id="msg"
                                                        name="msg"
                                                        type="text"
                                                        value={msg}
                                                        onChange={(e) => setMsg(e.target.value)}
                                                    />

                                                </div>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", gap: "5px" }}>
                                                <div className="col-md-6">
                                                    {!formSubmitted ? (
                                                        <div className="form-error-message">
                                                            {formErrorMsg}
                                                        </div>
                                                    ) : (
                                                        <div className="form-success-message">
                                                            {formErrorMsg}
                                                        </div>)}

                                                </div>
                                                <div className="col-md-6">
                                                    <div className="contactHomeSubmit text-end">
                                                        <button type="submit" className="contactBtnForm">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="informationForContact">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="contactDtails" data-aos="fade-in" data-aos-duration="10000">
                                    <h2>Contact Information :</h2>
                                    <div className="callNumber">
                                        <i className="fa-solid fa-phone"></i>
                                        <h4>
                                            <a href={`tel:${data?.contact_number}`} style={{ all: 'unset', cursor: "pointer" }}>
                                                {data?.contact_number}
                                            </a>
                                        </h4>
                                    </div>
                                    <div className="callNumber">
                                        <i className="fa-solid fa-at"></i>
                                        <h4>
                                            <a
                                                href={`mailto:${data?.contact_footer?.contact_section?.contact_email}`}
                                                style={{ all: 'unset', cursor: 'pointer' }}
                                            >
                                                {data?.contact_footer?.contact_section?.contact_email}
                                            </a>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="contactDtails" data-aos="fade-in" data-aos-duration="10000">
                                    <h2>Address :</h2>
                                    <div className="callNumber">
                                        <i className="fa-solid fa-house"></i>
                                        <a
                                            href={`https://www.google.com/maps/?q=${data?.longitude || 30.187894},${data?.latitude || -81.575112}`}
                                            rel="noopener noreferrer"
                                            target="_blank"

                                        >
                                            {typeof data?.contact_footer?.contact_section?.contact_address === "string"
                                                ? parse(data?.contact_footer?.contact_section?.contact_address)
                                                : null}
                                        </a>
                                    </div>
                                    {/* <div className="callNumber">
                                        <h4 className="mapDirection">Map and Direction [+]</h4>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="floridaMap" style={{ width: "100%" }}>
                    <iframe
                        src={data?.contact_footer?.contact_section?.map_url}
                        style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>

            <footer className="footer-section">
                <div className="container custom-container">
                    <div className="footer-content pt-5 pb-3" data-aos="fade-in" data-aos-duration="10000">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="footer-widget">
                                    <div className="footer-logo">
                                        <img
                                            src={whiteLogo}
                                            className="img-fluid"
                                            alt="logo"
                                            onClick={() => { window.scrollTo(0, 0); navigate('/') }}                                            
                                        />
                                    </div>
                                    <div className="footer-social-icon">
                                        {data?.acf_fields?.map((social, index) => (
                                            <a
                                                key={index}
                                                href={social?.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className={social?.icon}></i>
                                            </a>
                                        ))}
                                    </div>

                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4">
                                <div className="footer-widget">
                                    <ul>
                                        {/* <li><button onClick={() => { window.scrollTo(0, 0); navigate('/faq') }}>FAQ</button></li> */}
                                        {/* <li><button onClick={() => { window.scrollTo(0, 0); navigate('/articles') }}>Articles</button></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4">
                                <div className="footer-widget">
                                    <ul>
                                        <li><button onClick={() => { window.scrollTo(0, 0); navigate('/') }}>Home</button></li>
                                        <li><button onClick={() => { window.scrollTo(0, 0); navigate('/about-us') }}>About</button></li>
                                        <li><button onClick={() => { window.scrollTo(0, 0); navigate('/contact-us') }}>Contact</button></li>
                                        <li><button onClick={() => { window.scrollTo(0, 0); navigate('/faq') }}>FAQ</button></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4">
                                <div className="footer-widget">
                                    <ul>
                                        <li><button onClick={() => { window.scrollTo(0, 0); navigate('/general-service-repair') }}>General Service & Repair</button></li>
                                        <li><button onClick={() => { window.scrollTo(0, 0); navigate('/water-heater') }}>Water Heater</button></li>
                                        <li><button onClick={() => { window.scrollTo(0, 0); navigate('/water-treatment') }}>Water Treatment</button></li>
                                        <li><button onClick={() => { window.scrollTo(0, 0); navigate('/remodel-renovation') }}>Remodel & Renovation</button></li>
                                        <li><button onClick={() => { window.scrollTo(0, 0); navigate('/newConstruction') }}>New Construction</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="copyright-text">
                            <p>Copyright &copy; 2024, Hall & Sons Plumbing Inc. All Rights Reserved. Powered by &nbsp;&nbsp;&nbsp;<a href="https://sociallexicon.com/"><img src={socialLexian} alt="" /></a></p>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer