import React from 'react'
import pipe from "./../../assets/images/pipe.png";
import pipe2 from "./../../assets/images/pipe2.png";
import wrench from "./../../assets/images/wrench.png";
import tool from "./../../assets/images/tool.png";
import parse from 'html-react-parser';

const AboutUS = (props) => {

    const { data } = props;

    return (
        <>
            <section className="aboutUsPg">
                <div className="container custom-container">
                    <div className="aboutTextHead serviceHeading text-center" data-aos="fade-in" data-aos-duration="1000">
                        <h2 className="mobService" >
                            ABOUT US
                        </h2>
                    </div>
                    <div className="aboutPageDtls" data-aos="fade-in" data-aos-duration="1000">
                        <h2>{data?.welcome_section?.about_welcome_sub_heading}</h2>
                        {parse(data?.welcome_section?.about_welcome_sub_heading_content ?? "")}
                    </div>
                    <div className="aboutPageDtls" data-aos="fade-in" data-aos-duration="1000">
                        <h2>{data?.welcome_section?.about_welcome_second_sub_heading}</h2>
                        {parse(data?.welcome_section?.about_welcome_second_sub_heading_content ?? "")}
                    </div>
                </div>
            </section>

            <section className="servicesSection py-0">

                <div className="meetTeamAboutPage" style={{ backgroundImage: `url(${data?.welcome_section?.about_welcome_image})` }}>
                    <div className="pipeOne" data-aos="zoom-in" data-aos-duration="1200">
                        <img src={pipe} alt="" />
                    </div>
                    <div className="pieTwo" data-aos="zoom-in" data-aos-duration="1200">
                        <img src={pipe2} alt="" />
                    </div>
                    <div className="wrenchImg" data-aos="zoom-in" data-aos-duration="1200">
                        <img src={wrench} alt="" />
                    </div>
                    <div className="toolKitImg" data-aos="zoom-in" data-aos-duration="1400">
                        <img src={tool} alt="" />
                    </div>
                </div>

                <div className="bgVioletClr">
                    <div className="container custom-container">
                        <div className="row h-100 centerAligned">
                            <div className="aboutPgWhy serviceHeading text-center" data-aos="fade-in" data-aos-duration="1000">
                                <h2 className="mobService" >
                                    {data?.about_why_section?.about_why_choose_heading}
                                </h2>
                            </div>
                            <div className="collageText text-center mb-5" data-aos="fade-in" data-aos-duration="1000">
                                {parse(data?.about_why_section?.about_why_choose_content ?? "")}
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <div className="constructionDetails" data-aos="fade-in" data-aos-duration="1000">
                                    <h2 className='mb-4'>{data?.about_why_section?.about_why_choose_sub_heading}</h2>
                                    {parse(data?.about_why_section?.about_why_choose_sub_heading_content ?? "")}
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <div className="teamImgsCollage" data-aos="fade-in" data-aos-duration="1000">
                                    <img src={data?.about_why_section?.about_why_choose_image} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ourFleet" style={{ backgroundImage: `url(${data?.fleet_section?.our_fleet_banner_image})` }}>
                    <div className="fleetHeading serviceHeading text-center" style={{width:"40%"}} >
                        <h2 className="mobService mb-3" >
                            <span>
                                {data?.fleet_section?.our_fleet_heading}
                            </span>
                        </h2>
                        {parse(data?.fleet_section?.our_fleet_sub_content ?? "")}
                    </div>
                </div>

                <div className="createVeteranHistoryNext">
                    <div className="createVeteranHistoryImg">
                        <div className="container custom-container">
                            <div className="row align-items-center">
                                <div className="col-md-12 col-lg-5 col-xl-5">
                                    <div className="ourHistoryText" data-aos="fade-in" data-aos-duration="1000">
                                        <div className="serviceHeading mb-3" data-aos="fade-in" data-aos-duration="1000">
                                            <h2 className="mobService" >
                                                <span>
                                                    {data?.fleet_section?.our_fleet_second_heading}
                                                </span>
                                            </h2>
                                        </div>                                           {parse(data?.fleet_section?.our_fleet_second_content ?? "")}
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-7 col-xl-7">
                                    <div className="historyImg">
                                        <img src={data?.fleet_section?.our_fleet_image} alt="" data-aos="fade-in" data-aos-duration="1000" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container custom-container">
                    <div className="aboutPageDtls" data-aos="fade-in" data-aos-duration="1000">
                        <h2>{data?.fleet_section?.our_fleet_sub_heading}</h2>
                        {parse(data?.fleet_section?.our_fleet_sub_heading_content ?? "")}
                    </div>
                    <div className="aboutPageDtls" data-aos="fade-in" data-aos-duration="1000">
                        <h2>{data?.fleet_section?.our_fleet_second_sub_heading}</h2>
                        {parse(data?.fleet_section?.our_fleet_second_sub_heading_content ?? "")}
                    </div>
                    <div className="aboutPageDtls" data-aos="fade-in" data-aos-duration="1000">
                        <h2>{data?.fleet_section?.our_fleet_third_sub_heading}</h2>
                        {parse(data?.fleet_section?.our_fleet_third_sub_heading_content ?? "")}
                    </div>
                </div>
            </section>

            <section className="aboutLicense">
                <div className="container custom-container">
                    <div className="abtCenterLicense text-center" data-aos="fade-in" data-aos-duration="1000">
                        <img src={data?.fleet_section?.license_image} alt="" className='mx-auto' />
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUS