/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import logo from "./../../assets/images/logo.png";
import { useLocation, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

const Header = (props) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { data } = props;

    return (
        <main className="main-wrapper font-poppins">
            <div className="container-fluid mx-0 px-0">
                <div className="topbar">
                    <div className="container-fluid top-container">
                        <div className="topbar-inside">
                            <div className="contact-info">
                                <a style={{
                                    display: "flex",
                                    alignItems: "center", // Align image and text vertically centered 
                                    gap: "8px",
                                    textTransform: "capitalize" // Add space between the image and text 
                                }}><img
                                        src={data?.license_number_image} alt="" />
                                    {parse(data?.license_number ?? "")}
                                </a>
                            </div>
                            <div className="social-icons">
                                {data?.acf_fields?.map((social, index) => (
                                    <a
                                        key={index}
                                        href={social?.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className={social?.icon}></i>
                                    </a>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="container-fluid">
                <div className="wrapper">
                    <div className="logo"><button onClick={() => { window.scrollTo(0, 0); navigate('/') }}><img src={logo} alt="" /></button></div>
                    <input type="radio" name="slider" id="menu-btn" />
                    <input type="radio" name="slider" id="close-btn" />
                    <ul className="nav-links">
                        <label htmlFor="close-btn" className="btn close-btn"><i className="fas fa-times"></i></label>
                        <li><button className={pathname === "/" ? "active" : ""}
                            onClick={() => { window.scrollTo(0, 0); navigate('/') }}>Home</button></li>
                        <li><button className={pathname === "/about-us" ? "active" : ""}
                            onClick={() => { window.scrollTo(0, 0); navigate('/about-us') }}>About Us</button></li>
                        <li className="dropdownMenu">
                            <button className={`desktop-item ${pathname === '/general-service-repair' || pathname === '/water-treatment' || pathname === '/remodel-renovation' || pathname === '/water-treatmen' ? 'active' : ''}`}>Services <i className="fa-solid fa-chevron-down ps-1"></i></button>
                            <input type="checkbox" id="showDrop" />
                            <label htmlFor="showDrop" className="mobile-item">
                                Services
                                <i className="fa-solid fa-chevron-down ps-1"></i>
                            </label>
                            <ul className="drop-menu">
                                <li><button className='head-a' onClick={() => { window.scrollTo(0, 0); navigate('/general-service-repair') }}>General Service & Repair</button></li>
                                <li><button className='head-a' onClick={() => navigate('/water-heater')}>Water Heater</button></li>
                                <li><button className='head-a' onClick={() => { window.scrollTo(0, 0); navigate('/water-treatment') }}>Water Treatment</button></li>
                                <li><button className='head-a' onClick={() => navigate('/remodel-renovation')}>Remodel & Renovation</button></li>
                                <li><button className='head-a' onClick={() => { window.scrollTo(0, 0); navigate('/newConstruction') }}>New Construction</button></li>
                            </ul>
                        </li>
                        {/* <li className="dropdownMenu">
                            <button className={`desktop-item ${pathname === '/faq' || pathname === '/articles' ? 'active' : ''}`}>FAQ <i className="fa-solid fa-chevron-down ps-1"></i></button>
                            <input type="checkbox" id="showDropArtic" />
                            <label htmlFor="showDropArtic" className="mobile-item">FAQ <i
                                className="fa-solid fa-chevron-down ps-1"></i></label>
                            <ul className="drop-menu">
                                <li><button className='head-a' onClick={() => { window.scrollTo(0, 0); navigate('/faq') }}>FAQ</button></li>
                                <li><button className='head-a' onClick={() => { window.scrollTo(0, 0); navigate('/articles') }}>Articles</button></li>
                            </ul>
                        </li> */}
                        <li><button className={pathname === "/faq" ? "active" : ""}
                            onClick={() => { window.scrollTo(0, 0); navigate('/faq') }}>Faq</button></li>


                        <li><button className={pathname === "/contact-us" ? "active" : ""}
                            onClick={() => { window.scrollTo(0, 0); navigate('/contact-us') }}>Contact</button></li>
                        <li>
                            <button
                                className="callBgClr"
                                onClick={() => window.location.href = `tel:${data?.contact_number}`}
                            >
                                <i className="fa-solid fa-phone"></i> {data?.contact_number}
                            </button>
                        </li>
                    </ul>
                    <label htmlFor="menu-btn" className="btn menu-btn"><i className="fas fa-bars"></i></label>
                </div>
            </nav>
        </main>
    )
}

export default Header;