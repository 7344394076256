import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Article from '../components/articles';

const Articles = () => {

    const [pageData, setPageData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    'https://hallandsons.sociallexicon.com/backend/wp-json/v1/articles'
                );

                setPageData(response.data || {});
            } catch (error) {
                console.log("🚀 ~ fetchData ~ error:", error)
            }
        };

        fetchData();
    }, []);


    return (
        <Article data={pageData} />
    )
}

export default Articles;