import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ArticleDetail from '../components/articleDetail';
import { useSearchParams } from "react-router-dom";

const ArticleDetails = () => {

    const [pageData, setPageData] = useState();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `https://hallandsons.sociallexicon.com/backend/wp-json/v1/single-article/${id}`
                );

                setPageData(response.data || {});
            } catch (error) {
                console.log("🚀 ~ fetchData ~ error:", error)
            }
        };

        fetchData();
    }, [id]);


    return (
        <ArticleDetail data={pageData} />
    )
}

export default ArticleDetails;