/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef } from 'react';
import parse from 'html-react-parser';
import hallsons from "./../../assets/images/hall-&-sons-logo.png";
import { useNavigate } from 'react-router-dom';


const HomeComponent = (props) => {
    const { data } = props;
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedService, setSelectedService] = useState("General Service Repair");
    const [firstStep, setFirstStep] = useState(true);
    const [secondStep, setSecondStep] = useState(false);
    const [thirdStep, setThirdStep] = useState(false);
    const [fourthStep, setFourthStep] = useState(false);
    const [fifthStep, setFifthStep] = useState(false);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = data?.our_work_section?.our_work_gallery || [];
    const [isFullscreen, setIsFullscreen] = useState(false);
    const imgRef = useRef(null);

    const showBookingForm = () => {
        setIsModalOpen(prev => !prev)
    }

    const handleServiceChange = (event) => {
        setSelectedService(event.target.value); // Update selected service
    };

    const openImageModal = (image) => {
        setSelectedImage(image);
        setIsImageModalOpen(true);
    };

    // Handle close modal
    const closeImageModal = (e) => {
        e.stopPropagation();  // Prevents the click event from bubbling up
        setIsImageModalOpen(false);  // Close the modal
    };


    const handlePrevImage = () => {
        const prevIndex = currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1;
        setCurrentImageIndex(prevIndex);
        setSelectedImage(images[prevIndex]);
    };

    const handleNextImage = () => {
        const nextIndex = currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1;
        setCurrentImageIndex(nextIndex);
        setSelectedImage(images[nextIndex]);
    };

    const handleNavigate = (index) => {
        window.scrollTo(0, 0);
        switch (index) {
            case 0:
                navigate("/general-service-repair");
                break;
            case 2:
                navigate("/water-treatment");
                break;
            case 3:
                navigate("/remodel-renovation");
                break;
            case 4:
                navigate("/new-construction");
                break;
            default:
                console.warn(`No navigation defined for index: ${index}`);
        }
    };

    const handleFullscreen = () => {
        if (isFullscreen) {
            // Check if the modal is in fullscreen and exit fullscreen
            if (document.fullscreenElement === document.getElementById('bookingModal')) {
                document.exitFullscreen();
            }
        } else {
            // Request fullscreen for the modal itself
            const modalElement = document.getElementById('bookingModal');
            if (modalElement?.requestFullscreen) {
                modalElement.requestFullscreen();
            }
        }
        setIsFullscreen(!isFullscreen);
    };

    return (
        <>
            <section className="heroSecimg font-poppins">
                <div className="main-img bg-center" style={{ backgroundImage: `url(${data?.banner_section?.banner_main_image})` }}>
                    <div className="heroImgText">
                        <img src={data?.banner_section?.banner_image} alt="" />
                    </div>
                </div>
                {/* <div className="heroContentWdthBtn">
                    <div className="heroBtn text-center">
                        <button type="button" className="heroBtnBookNow" onClick={showBookingForm} data-aos="fade-in" data-aos-duration="1000">{data?.banner_section?.banner_button}</button>
                    </div>
                </div> */}
            </section>

            <section className="servicesSection">
                <div className="container custom-container">

                    <div className="serviceHeading text-center" data-aos="fade-in" data-aos-duration="1000">
                        <h2 className="mobService fontMotter" >
                            <span className='fontMotter'>
                                {data?.service_section?.service_section_heading}
                            </span>
                        </h2>
                    </div>

                    <div className="servicesImg">
                        {data?.service_section?.service_images?.map((service, index) => {
                            return (<div
                                key={index}
                                className="flip-container"
                                data-aos="fade-up"
                                data-aos-duration={`${600 + index * 200}`}
                                onClick={() => handleNavigate(index)} // Handle the click to navigate
                            >
                                <div className="flipper">
                                    <div clas="front">
                                        <img className="frontBackImg" src={service?.service_front_image} alt="" style={{ cursor: "pointer" }} />
                                    </div>
                                    <div className="back">
                                        <img className="frontBackImg" src={service?.service_back_image} alt="" style={{ cursor: "pointer" }} />
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className="servingFloridaSection">
                <div className="reliabilityHeading text-center" data-aos="fade-in">
                    <h2>{data?.on_road_section?.on_road_heading}</h2>
                </div>
                <div className="floridaBg">
                    <div className="floridaTextUp serviceHeading text-center" data-aos="fade-in" data-aos-duration="1000">
                        <h2 className="mobService fontMotter">
                            <span className='fontMotter'>
                                {data?.on_road_section?.on_road_sub_heading} <strong className='onlyViolate'>{data?.on_road_section?.on_road_year}</strong>
                            </span>
                        </h2>
                    </div>

                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="floridaMap" data-aos="fade-in" data-aos-duration="1000">
                                    <img src={data?.on_road_section?.on_road_image} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mapWithText" data-aos="fade-in" data-aos-duration="1000" >
                                    {typeof data?.on_road_section?.on_road_description === "string"
                                        ? parse(data?.on_road_section?.on_road_description)
                                        : null}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="setsApartSec">
                <div className="container custom-container">
                    <div className="setsApartBg">
                        <div className=" apartLeft mb-0 serviceHeading " data-aos="fade-in" data-aos-duration="1000">
                            <h2 className="mobService" >
                                <span>
                                    {data?.quality_section?.quality_heading} {data?.quality_section?.quality_red_heading}
                                </span>
                            </h2>
                        </div>
                        <div className="apartRight" data-aos="fade-in" data-aos-duration="1000">
                            <img src={hallsons} alt="" />
                        </div>

                    </div>
                    <div className="row">

                        <div className="col-lg-6">
                            <div className="plumberWithText" data-aos="fade-in" data-aos-duration="1000">
                                <h2>{data?.quality_section?.quality_sub_heading}</h2>
                                {typeof data?.on_road_section?.on_road_description === "string"
                                    ? parse(data?.quality_section?.quality_description)
                                    : null}
                                <div className="listingParts">
                                    <div className="row">
                                        {data?.quality_section?.quality_list.map((list, index) => (
                                            <div className="col-lg-6 col-sm-6 col-6" key={index}>
                                                <div className="listIconsText">
                                                    <img
                                                        src={list?.quality_list_image}
                                                        alt={list?.quality_list_text || "Quality item image"}
                                                    />
                                                    <h4>{list?.quality_list_text}</h4>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="plumberImg" data-aos="fade-up" data-aos-duration="1000">
                                <img src={data?.quality_section?.quality_image} alt="" className='ml-auto' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="servicesSection">
                <div className="container custom-container">
.
                    <div className="workService serviceHeading text-center" data-aos="fade-in" data-aos-duration="1000">
                        <h2 className="mobService" >
                            <span>
                                {data?.our_work_section?.our_work_heading}
                            </span>
                        </h2>
                    </div>

                    <div className="photo-gallery">
                        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-4 gallery-grid">
                            {data?.our_work_section?.our_work_gallery?.map((image, index) => {
                                const duration = 800 + (index % 4) * 200;
                                return (
                                    <div className="col" data-aos="fade-up"
                                        data-aos-anchor-placement="top-bottom"
                                        data-aos-duration={duration}
                                        key={index}>
                                        <a className="gallery-item" onClick={(e) => {
                                            e.preventDefault(); // Prevent default behavior
                                            openImageModal(image, `Image ${index + 1}`);
                                            setCurrentImageIndex(index); // Store the index of the selected image

                                        }}>
                                            <img className="gallery" src={image} alt={`Image ${index + 1}`} />
                                        </a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>


                    {/* <div className="galleryImgs">

                        {data?.our_work_section?.our_work_gallery?.map((image, index) => {
                            const duration = 800 + (index % 4) * 200;
                            return (
                                <div
                                    className="responsiveGallery"
                                    data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom"
                                    data-aos-duration={duration}
                                    key={index}
                                >
                                    <img className="popImg" src={image} alt={index} />
                                </div>
                            );
                        })}
                    </div> */}

                    <div className="clearfix"></div>

                    {isImageModalOpen && (
                        <div id="bookingModal" className="modal">
                            <div className='modalControls'>
                                <i className="fa-solid fa-expand" onClick={handleFullscreen}></i>
                                <i onClick={closeImageModal} className="fa-solid fa-xmark"></i>
                            </div>
                            <div className='modalImgWrapper'>
                                <button onClick={handlePrevImage} className="modal-nav-button prev" aria-label="Previous Image">
                                    &lt;
                                </button>
                                <div className="modalCaroselImg">
                                    <img ref={imgRef} className="modal-content galleryModalImg" src={selectedImage} alt="Modal View" />
                                </div>
                                <button onClick={handleNextImage} className="modal-nav-button next" aria-label="Next Image">
                                    &gt;
                                </button>
                            </div>
                        </div>
                    )}

                </div>
            </section>


            <section className="servicesSection">
                <div className="container">

                    <div className="teamimgWIthText text-center pb-4" data-aos="fade-in" data-aos-duration="1000">
                        <div className="teamimgWIthTextHome  serviceHeading text-center" data-aos="fade-in" data-aos-duration="1000">
                            <h2 className="mobService" >
                                <span>
                                    {data?.team_section?.team_heading}
                                </span>
                            </h2>
                        </div>
                        <div className="reliabilityHeading text-center mb-5">
                            <h2>{data?.team_section?.team_first_sub_heading}</h2>
                            {parse(data?.team_section?.team_first_sub_description ?? "")}
                        </div>

                        <div className="reliabilityHeading text-center mb-5">
                            <h2>{data?.team_section?.team_second_sub_heading}</h2>
                            {parse(data?.team_section?.team_second_sub_description ?? "")}
                        </div>

                    </div>
                </div>

                <div className="meetTeam">
                    <img src={data?.team_section?.team_image} data-aos="fade-in" data-aos-duration="1000" alt="" />
                    <div className="memberLearnBtn text-center" data-aos="fade-in" data-aos-duration="1400">
                        <button type="button" className="learnMore" onClick={() => { window.scrollTo(0, 0); navigate('/about-us') }}>{data?.team_section?.about_us_button_text}</button>
                    </div>
                </div>
            </section>


            <section className="customerReviewSec">
                <div className="container custom-container">
                    <div className="customerUpperText text-center" data-aos="fade-in" data-aos-duration="1000">
                        <h4>{data?.review_section?.review_heading}</h4>
                        <div className="serviceHeading text-center" data-aos="fade-in" data-aos-duration="1000">
                            <h2 className="mobService" >
                                <span>
                                    {data?.review_section?.review_sub_heading}
                                </span>
                            </h2>
                        </div>                       </div>

                    <div className="row g-4">
                        {data?.review_section?.review_slider.map((slide, index) => (
                            <div
                                key={index}
                                className="col-md-6 col-lg-6 col-xl-3 order-xl-1 order-md-1"
                            >
                                <div
                                    className="cutomerReviewBoxes"
                                    data-aos="fade-up"
                                    data-aos-duration="600"
                                >
                                    <div className="testimonialIconText">
                                        <span>
                                            <i className="fa-solid fa-thumbs-up"></i>
                                            {slide?.review_slider_title}
                                        </span>
                                    </div>
                                    <div className="bgWhiteInside">
                                        <div className="reviewInsideBox">
                                            <img
                                                src={slide?.review_slider_image}
                                                alt={slide?.review_slider_name || "Customer review image"}
                                            />
                                        </div>
                                        <div className="rating">
                                            {typeof slide?.review_slider_rating === "number" &&
                                                Array.from({ length: slide.review_slider_rating }).map(
                                                    (_, index) => (
                                                        <i key={index} className="fa-solid fa-star"></i>
                                                    )
                                                )}
                                        </div>
                                        <div className="parawithClinetName">
                                            <div className="clientFeedback">
                                                <p className="clientReviewText">
                                                    {slide?.review_slider_description}
                                                </p>
                                            </div>
                                            <div className="clientName">
                                                <h4>{slide?.review_slider_name}</h4>
                                                <span>{slide?.review_slider_occupation}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* <div className="moreReviews" data-aos="fade-up" data-aos-duration="1200">
                        <div className="googleRevies">
                            <img src={googlePNG} alt="" />
                        </div>
                        <div className="reviewLink">
                            <h4>Based on 236.488 Review<br />In Google Business</h4>
                            <a href="#">More Testimonial <i className="fa-solid fa-arrow-up-right-from-square ps-1"></i></a>
                        </div>
                    </div> */}
                </div>
            </section>


            {/* popup modal  */}

            {isModalOpen && (<div id="bookingModal" className="modal" >
                <div className="modal-content form-slider">
                    <span className="close" onClick={showBookingForm}>×</span>
                    <div className="col-md-12">
                        <div className="formInsideSpace">
                            {/* <div className="heroContentWdthBtn">
                                <div className="heroBtn text-center">
                                    <button type="button" className="heroBtnBookNow" data-aos="fade-in" data-aos-duration="1000"
                                        >Book Now</button>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="multisteps-form">
                                    <div className="row">
                                        <div className="col-12 ml-auto mt-4 mr-auto mb-4">
                                            <div className="multisteps-form__progress">
                                                <button
                                                    className={`multisteps-form__progress-btn ${firstStep ? "js-active" : ""}`}
                                                    type="button"
                                                    title="Select Service"
                                                >
                                                    Select Service
                                                </button>
                                                <button
                                                    className={`multisteps-form__progress-btn ${secondStep ? "js-active" : ""}`}
                                                    type="button"
                                                    title="Issues"
                                                >
                                                    Issues
                                                </button>
                                                <button className={`multisteps-form__progress-btn ${thirdStep ? "js-active" : ""}`} type="button"
                                                    title="Returning customer">Returning customer</button>
                                                <button className={`multisteps-form__progress-btn ${fourthStep ? "js-active" : ""}`} type="button"
                                                    title="Preferred date">Preferred date</button>
                                                <button className={`multisteps-form__progress-btn ${fifthStep ? "js-active" : ""}`} type="button"
                                                    title="Message">Message </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 m-auto">
                                            <form className="multisteps-form__form">
                                                {firstStep && <div className={`multisteps-form__panel shadow p-4 rounded bg-white ${firstStep ? "js-active" : ""}`}
                                                    data-animation="fade">
                                                    <h3 className="multisteps-form__title">Select Service</h3>
                                                    <div className="multisteps-form__content">
                                                        <div className="col-md-12">
                                                            <div className="selectServices">
                                                                <ul className="radioDirection">
                                                                    <li>
                                                                        <label className="radioContainer">
                                                                            <input
                                                                                type="radio"
                                                                                name="services"
                                                                                value="General Service Repair"
                                                                                checked={selectedService === "General Service Repair"}
                                                                                onChange={handleServiceChange}
                                                                            />
                                                                            <span className="checkmark"></span>General Service Repair
                                                                        </label>
                                                                    </li>

                                                                    {/* <li>
                                                            <label className="radioContainer">
                                                                <input
                                                                    type="radio"
                                                                    name="services"
                                                                    value="Water Heater"
                                                                    checked={selectedService === "Water Heater"}
                                                                    onChange={handleServiceChange}
                                                                />
                                                                <span className="checkmark"></span>Water Heater
                                                            </label>
                                                        </li> */}

                                                                    <li>
                                                                        <label className="radioContainer">
                                                                            <input
                                                                                type="radio"
                                                                                name="services"
                                                                                value="Water Treatment"
                                                                                checked={selectedService === "Water Treatment"}
                                                                                onChange={handleServiceChange}
                                                                            />
                                                                            <span className="checkmark"></span>Water Treatment
                                                                        </label>
                                                                    </li>

                                                                    <li>
                                                                        <label className="radioContainer">
                                                                            <input
                                                                                type="radio"
                                                                                name="services"
                                                                                value="Remodel & Renovation"
                                                                                checked={selectedService === "Remodel & Renovation"}
                                                                                onChange={handleServiceChange}
                                                                            />
                                                                            <span className="checkmark"></span>Remodel & Renovation
                                                                        </label>
                                                                    </li>

                                                                    <li>
                                                                        <label className="radioContainer">
                                                                            <input
                                                                                type="radio"
                                                                                name="services"
                                                                                value="New Construction"
                                                                                checked={selectedService === "New Construction"}
                                                                                onChange={handleServiceChange}
                                                                            />
                                                                            <span className="checkmark"></span>New Construction
                                                                        </label>
                                                                    </li>


                                                                </ul>
                                                            </div>
                                                        </div>


                                                        <div className="button-row d-flex justify-content-center gap-2 mt-4">
                                                            <button className="btn btn-primary ml-auto js-btn-next" type="button"
                                                                title="Next" onClick={() => {
                                                                    setFirstStep(false);
                                                                    setSecondStep(true);
                                                                }}>Next</button>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {secondStep && (
                                                    <div className={`multisteps-form__panel shadow p-4 rounded bg-white ${secondStep ? "js-active" : ""}`}
                                                        data-animation="fade">
                                                        <h3 className="multisteps-form__title">What Issues are you having
                                                        </h3>
                                                        <div className="multisteps-form__content">

                                                            <div className="col-md-12">
                                                                <div className="contactHome_new">
                                                                    <input type="text" className="form-control" name="issues"
                                                                        placeholder="Describe your issues..." />
                                                                </div>
                                                            </div>


                                                            <div className="button-row d-flex justify-content-center gap-2 mt-4">
                                                                <button className="btn btn-primary js-btn-prev" type="button"
                                                                    title="Prev" onClick={() => {
                                                                        setFirstStep(true)
                                                                        setSecondStep(false)
                                                                    }}>Prev</button>
                                                                <button className="btn btn-primary ml-auto js-btn-next" type="button"
                                                                    title="Next" onClick={() => {
                                                                        setThirdStep(true)
                                                                        setSecondStep(false)
                                                                    }}>Next</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {thirdStep && <div className={`multisteps-form__panel shadow p-4 rounded bg-white ${thirdStep ? "js-active" : ""}`}
                                                    data-animation="fade">
                                                    <h3 className="multisteps-form__title">Returning customer?</h3>
                                                    <div className="multisteps-form__content">

                                                        <div className="col-md-12">
                                                            <div className="contactHome_new">
                                                                <label className="me-2">
                                                                    <input type="radio" name="returning_customer" value="yes"
                                                                        selected />
                                                                    Yes
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="returning_customer" value="no"
                                                                        checked /> No
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12 returning-fields bookingFlex" >
                                                            <div className="row">
                                                                <div className="col-md-6 col-12">
                                                                    <div className="contactHome_new">
                                                                        <p>First Name</p>
                                                                        <input name="returning_name" type="text" className="form-control" placeholder="First name..." />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6 col-12">
                                                                    <div className="contactHome_new">
                                                                        <p>Last Name</p>
                                                                        <input name="returning_name" type="text" className="form-control" placeholder="Last name..." />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-12">
                                                                    <div className="contactHome_new">
                                                                        <p>Phone Number</p>
                                                                        <input name="returning_name" type="tel" className="form-control" placeholder="Phone number..." />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-12">
                                                                    <div className="contactHome_new">
                                                                        <p>Email Address</p>
                                                                        <input name="returning_name" type="email" className="form-control" placeholder="Email address..." />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-12">
                                                                    <div className="contactHome_new">
                                                                        <p>Service Address</p>
                                                                        <input name="returning_name" type="text" className="form-control" placeholder="Street Name..." />
                                                                    </div>

                                                                </div>
                                                                <div className="col-md-6 col-12">
                                                                    <div className="contactHome_new">
                                                                        <p>City</p>
                                                                        <input name="returning_name" type="text" className="form-control" placeholder="City..." />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-12">
                                                                    <div className="contactHome_new">
                                                                        <p>State</p>
                                                                        <input name="returning_name" type="text" className="form-control" placeholder="State..." />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 col-12">
                                                                    <div className="contactHome_new">
                                                                        <p>Zipcode</p>
                                                                        <input name="returning_name" type="text" className="form-control" placeholder="Zipcode..." />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <!-- Profile Information for News Customerss --> */}
                                                        <div className="col-md-12 new-customer-fields">
                                                            <div className="contactHome_new">
                                                                <p>Name</p>
                                                                <input name="new_name" className="form-control"
                                                                    placeholder="Your name..." required />
                                                            </div>
                                                            <div className="contactHome_new">
                                                                <p>Phone Number</p>
                                                                <input name="new_phone" className="form-control"
                                                                    placeholder="Your phone number..." required />
                                                            </div>
                                                            <div className="contactHome_new">
                                                                <p>Address</p>
                                                                <input name="new_address" className="form-control"
                                                                    placeholder="Your address..." required />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="button-row d-flex justify-content-center gap-2 mt-4 col-12">
                                                                <button className="btn btn-primary js-btn-prev" type="button"
                                                                    title="Prev" onClick={() => {
                                                                        setThirdStep(false)
                                                                        setSecondStep(true)
                                                                    }}>Prev</button>
                                                                <button className="contactHomeSubmit btn btn-primary ml-auto js-btn-next"
                                                                    type="button" title="Next" onClick={() => {
                                                                        setFourthStep(true)
                                                                        setThirdStep(false)
                                                                    }}>Next</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}



                                                {fourthStep && <div className={`multisteps-form__panel shadow p-4 rounded bg-white ${fourthStep ? "js-active" : ""}`}
                                                    data-animation="scaleIn">
                                                    <h3 className="multisteps-form__title">Preferred Date and time</h3>
                                                    <div className="multisteps-form__content">


                                                        <div className="col-md-12">
                                                            <div className="contactHome_new">
                                                                <input type="date" className="form-control"
                                                                    name="preferred_date_time" required />
                                                            </div>
                                                        </div>



                                                        <div className="button-row d-flex justify-content-center gap-2 mt-4">
                                                            <button className="btn btn-primary js-btn-prev" type="button"
                                                                title="Prev" onClick={() => {
                                                                    setThirdStep(true)
                                                                    setFourthStep(false)
                                                                }}>Prev</button>
                                                            {/* <!-- <div className="contactHomeSubmit text-start mb-4">
                                                        <button type="button"
                                                            onclick="document.getElementById('').click()"
                                                            className="contactBtnForm_new js-btn-next">Send
                                                            Message</button>
                                                    </div> --> */}
                                                            <div className="contactHomeSubmit text-start js-btn-next">
                                                                <button type="button" className="contactBtnForm_new" onClick={() => {
                                                                    setFifthStep(true)
                                                                    setFourthStep(false)
                                                                }}>Next</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {fifthStep && <div className={`multisteps-form__panel shadow p-4 rounded bg-white ${fifthStep ? "js-active" : ""}`}
                                                    data-animation="fade">
                                                    <div className="multisteps-form__content">

                                                        <p>We will text or email you to confirm or suggest a different time
                                                            after reviewing our schedule.</p>

                                                        <div className="button-row d-flex gap-2 justify-content-center mt-4">
                                                            <button className="btn btn-primary js-btn-prev" type="button"
                                                                title="Prev"
                                                                onClick={() => {
                                                                    setFourthStep(true)
                                                                    setFifthStep(false)
                                                                }}
                                                            >Prev</button>
                                                        </div>
                                                    </div>
                                                </div>}

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}

        </>
    )
}

export default HomeComponent