import React, { useState, useEffect } from 'react';

const WhiteScreenOverlay = () => {
    const [loading, setLoading] = useState(true);

    // Simulate a delay (e.g., 1 second)
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false); // Set loading to false after 1 second
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return <div style={overlayStyle}></div>; // Show the white overlay when loading
    }

    return null; // No overlay when loading is false
};

// Styles for the white screen overlay
const overlayStyle = {
    position: 'relative',
    top: '150px', // Adjust this depending on your header's height
    left: 0,
    width: '100vw', // Make the overlay take the full width of the viewport
    height: 'calc(100vh - 60px)', // Subtract the header height from the total viewport height
    backgroundColor: 'white', // Solid white background
    zIndex: 1000, // Ensure it overlays on top of other components
  };

export default WhiteScreenOverlay;
